angular.module('webpanel').service('epg', ['api', '$q', 'popup', function(api, $q, popup) {
	var epgData = {};
	var observers = {};

	var chunkSize = 5400;

	var get = function() {
		return angular.copy(epgData);
	}

	var query = function(channels, from) {
		console.log(channels);
		var chunksToLoad = {
			previous: { from: from-chunkSize, channels: [] },
			current: { from: from, channels: [] },
			next: { from: from+chunkSize, channels: [] },
			nextNext: { from: from+(2*chunkSize), channels: [] },
		}

		for(var channelIndex in channels) {
			var chanelId = channels[channelIndex];

			if(typeof epgData[chanelId] !== 'undefined') {
				var previousChunkStart = from-chunkSize;
				var nextChunkStart = from+chunkSize;
				var nextNextChunkStart = from+(2*chunkSize);

				// załaduj tylko te kanały z tych chunków, których faktycznie nie mamy
				// nie ładujemy chunka ze wszystkimi kanałami w widoku jesli brakuje tylko jednego
				// tak samo nie ładujemy wszystkich trzech chunków jeśli mamy już np. dwa z tym kanałem
				if(typeof epgData[chanelId][previousChunkStart] === 'undefined') chunksToLoad.previous.channels.push(chanelId);
				if(typeof epgData[chanelId][from] === 'undefined') chunksToLoad.current.channels.push(chanelId);
				if(typeof epgData[chanelId][nextChunkStart] === 'undefined') chunksToLoad.next.channels.push(chanelId);
				if(typeof epgData[chanelId][nextNextChunkStart] === 'undefined') chunksToLoad.nextNext.channels.push(chanelId);

			} else {
				chunksToLoad.previous.channels.push(chanelId);
				chunksToLoad.current.channels.push(chanelId);
				chunksToLoad.next.channels.push(chanelId);
				chunksToLoad.nextNext.channels.push(chanelId);
			}
		}

		var promises = [];

		for(var position in chunksToLoad) {
			if(chunksToLoad[position].channels.length) {
				var promise = fetch(chunksToLoad[position].channels, chunksToLoad[position].from);
				promises.push(promise);
			}
		}

		$q.all(promises).then(function() {
			notifyObservers('change');
		});
	}

	var fetch = function(channels, from) {
		return api.epgGetChunk(from, channels).then(function(response) {
			for(var i in response.data.chunk) {
				if(typeof epgData[i] === 'undefined') epgData[i] = {};
				epgData[i][response.data.start] = response.data.chunk[i];
			}
		}).catch(function() {
			var pop = popup.create();
			pop.setText('Wystąpił błąd podczas pobierania danych. Spróbuj ponownie. '+
				'Jeśli problem będzie się powtarzał, skontaktuj się ze swoim Operatorem.');
			pop.addButton('OK', angular.noop, 'yes', true);
			popup.push(pop);
		});
	}

	var addObserver = function(name, observer) {
		if(typeof observer !== 'function') return;
		if(typeof observers[name] !== 'undefined') return;
		observers[name] = observer;
	}

	var removeObserver = function(name) {
		delete observers[name];
	}

	var notifyObservers = function(eventType, eventData) {
		for(var i in observers) observers[i](eventType, eventData);
	}

	return {
		get: get,
		query: query,
		addObserver: addObserver,
		removeObserver: removeObserver,
	}

}]);