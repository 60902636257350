angular.module('webpanel').controller('SmsVerificationController', ['$scope', 'api', function($scope, api) {
    $scope.smsCode = null;

    $scope.verify = function() {
        api.verifySmsCode($scope.smsCode).then(function() {
            var pop = $scope.popup.create();
            pop.setText("Kod poprawny. Dziękujemy.");
            pop.addButton('OK', angular.noop, 'yes', true);
            $scope.popup.push(pop);
        }).catch(function() {
            var pop = $scope.popup.create();
            pop.setText("Prawdopodobnie wprowadzono niepoprawny kod z wiadomości SMS.");
            pop.addButton('Spróbuj ponownie', angular.noop, 'yes', true);
            $scope.popup.push(pop);
            $scope.smsCode = null;
        });
    }

    $scope.resendCode = function() {
        api.resendSmsCode().then(function() {
            var pop = $scope.popup.create();
            pop.setText("Kod SMS został wysłany ponownie.");
            pop.addButton('OK', angular.noop, 'yes', true);
            $scope.popup.push(pop);
        });
    }

    $scope.cancel = function() {
        $scope.smsCode = null;
    }
}]);
