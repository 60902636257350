angular.module('webpanel').factory('OrderService', function() {
    var service = {
        verificationStep: 0, // 0: no-op, 1: jpin verification, 2: sms verification, 3: captcha verification
        selectedPackage: null,
        jpin: null,
        captchaResponse: null,
        orderInProgress: false,

        setVerificationStep: function(step) {
            this.verificationStep = step;
        },
        
        setSelectedPackage: function(pkg) {
            this.selectedPackage = pkg;
        },

        setJpin: function(jpin) {
            this.jpin = jpin;
        },

        setCaptchaResponse: function(response) {
            this.captchaResponse = response;
        },

        setOrderInProgress: function(orderInProgress) {
            this.orderInProgress = orderInProgress;
        },

        reset: function() {
            this.verificationStep = 0;
            this.selectedPackage = null;
            this.jpin = null;
            this.captchaResponse = null;
            this.orderInProgress = false;
        }
    };

    return service;
});
