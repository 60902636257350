angular.module('webpanel').service('api', ['$http', 'auth', function($http, auth) {
	var apiUrl = "https://api-dev.sgtsa.pl/public/" || 'https://api.sgtsa.pl/';
	var stbApi = "https://api-new-dev.sgt.net.pl/";
	var api = {};

	var $ = auth.getHeaders;

	// -------------------------------------------------

	api.ping = function() {
		var route = 'v1/';
		return $http.get(apiUrl+route, $(route));
	}

	api.getRoot = function() {
		return apiUrl;
	}

	api.authCallback = $;

	// -------------------------------------------------

	api.userLogin = function(username, password, challengeResponse) {
		var params = {
			username: username,
			password: password,
		};

		if(typeof challengeResponse !== 'undefined') {
			params.challenge = challengeResponse;
		}

		// var route = 'v1/auth/login';
		var route = 'v2/auth/login';

		// return $http.post(apiUrl+route, params, {
			return $http.post(stbApi+route, params, {
			headers: {
				'X-Time': Math.floor(Date.now()/1000),
			}
		});
	}

	api.userGet = function() {
		var route = 'v1/user';
		return $http.get(apiUrl+route, $(route));
	}

	// -------------------------------------------------

	api.userPairProlong = function() {
		var route = 'v1/auth/prolong';
		return $http.post(apiUrl+route, {}, $(route));
	}

	api.userPairMassDestroy = function() {
		var route = 'v1/auth/pair/browser';
		return $http.delete(apiUrl+route, $(route));
	}

	// -------------------------------------------------

	api.devicesGet = function() {
		var route = 'v1/auth/devices';
		return $http.get(apiUrl+route, $(route));
	}

	// -------------------------------------------------

	api.castGetSession = function() {
		var route = 'v1/auth/pair/cast';
		return $http.get(apiUrl+route, $(route));
	}

	api.castGetToken = function(forDeviceId) {
		var route = 'v1/ott/token/cast/'+encodeURIComponent(forDeviceId);
		var routeNoEncode = 'v1/ott/token/cast/'+forDeviceId;

		// backend dekoduje route i automatycznie zdekoduje też id urządzenia
		return $http.get(apiUrl+route, $(routeNoEncode));
	}

	// -------------------------------------------------

	api.passwordChange = function(newPassword, oldPassword) {
		var route = 'v1/user/password';

		return $http.post(apiUrl+route, {
			oldPassword: oldPassword,
			newPassword: newPassword
		}, $(route));
	}

	api.jPinChange = function(newJPin, password) {
		var route = 'v1/user/jpin';

		return $http.post(apiUrl+route, {
			pin: newJPin,
			password: password
		}, $(route));
	}

	api.tvPinChange = function(newTvPin, password) {
		var route = 'v1/user/tvpin';

		return $http.post(apiUrl+route, {
			pin: newTvPin,
			password: password
		}, $(route));
	}

	api.emailChange = function(newEmail, credential, userVerified) {
		var data;
		var route = 'v1/user/email';

		if(userVerified) {
			data = {
				email: newEmail,
				password: credential
			};
		} else {
			data = {
				email: newEmail,
				jpin: credential
			};
		}

		return $http.post(apiUrl+route, data, $(route));
	}

	api.emailChangeConfirm = function(hash) {
		var route = 'v1/user/email/confirm';
		return $http.post(apiUrl+route, { token: hash }, $(route));
	}

	api.passwordReset = function(email) {
		var route = 'v1/user/password/reset';
		return $http.post(apiUrl+route, { email: email });
	}

	api.passwordResetConfirm = function(customerId, token, password) {
		var route = 'v1/user/password/reset/confirm';

		return $http.post(apiUrl+route, {
			customerId: customerId,
			token: token,
			password: password
		});
	}

	api.marketingAgreementsGet = function() {
		var route = 'v1/user/agreements';
		return $http.get(apiUrl+route, $(route));
	}

	api.marketingAgreementsSet = function(agreements) {
		var route = 'v1/user/agreements';
		return $http.post(apiUrl+route, agreements, $(route));
	}

	// -------------------------------------------------

	api.newsGet = function() {
		var route = 'v1/jamboxpl/news';
		return $http.get(apiUrl+route, $(route));
	}

	api.recommendedGet = function() {
		var route = 'v1/jamboxpl/recommended';
		return $http.get(apiUrl+route, $(route));
	}

	// -------------------------------------------------

	api.recordingGetChannels = function() {
		var route = 'v1/recording/channel/list';
		return $http.get(apiUrl+route, $(route));
	}

	api.recordingGetStorage = function() {
		var route = 'v1/recording/storage';
		return $http.get(apiUrl+route, $(route));
	}

	api.recordingGetAll = function() {
		var route = 'v1/recording';
		return $http.get(apiUrl+route, $(route));
	}

	api.recordingAdd = function(data) {
		var route = 'v1/recording';
		return $http.post(apiUrl+route, data, $(route));
	}

	api.recordingDelete = function(id) {
		if(typeof id === 'undefined' || !id) return;
		var route = 'v1/recording/'+id;

		return $http.delete(apiUrl+route, $(route));
	}

	api.recordingDeleteAll = function() {
		var route = 'v1/recording';
		return $http.delete(apiUrl+route, $(route));
	}

	api.recordingChange = function(id, data) {
		var route = 'v1/recording/'+id;
		return $http.put(apiUrl+route, data, $(route));
	}

	// -------------------------------------------------

	api.settingsFetch = function() {
		var route = 'v1/setting';
		return $http.get(apiUrl+route, $(route));
	}

	api.settingsWrite = function(settings) {
		var route = 'v1/setting';
		return $http.post(apiUrl+route, settings, $(route));
	}

	// -------------------------------------------------

	api.messagesFetch = function() {
		var route = 'v1/message';
		return $http.get(apiUrl+route, $(route));
	}

	api.messageRead = function(id) {
		var route = 'v1/message/read/'+id;
		return $http.post(apiUrl+route, {}, $(route));
	}

	// -------------------------------------------------

	api.epgGetChunk = function(from, channels) {
		var route = 'v1/epg/chunk/'+from+'/assets/'+channels.join(',');
		return $http.get(apiUrl+route, $(route));
	}

	api.epgGetCurrent = function(id) {
		var route = 'v1/epg/current/'+id;
		return $http.get(apiUrl+route, $(route));
	}

	api.epgGetCurrentAll = function() {
		var route = 'v1/epg/all';
		return $http.get(apiUrl+route, $(route));
	}

	// -------------------------------------------------

	api.assetsGet = function() {
		var route = 'v1/asset';
		return $http.get(apiUrl+route, $(route));
	}

	// -------------------------------------------------

	api.servicesGet = function() {
		var route = 'v1/service';
		return $http.get(apiUrl+route, $(route));
	}

	api.servicesGetWithAsset = function(id) {
		if(typeof id === 'undefined') return [];
		var route = 'v1/asset/containing/'+id;

		return $http.get(apiUrl+route, $(route));
	}

	api.servicesGetForOrder = function(mode, filter) {
		var route = 'v1/service/order/list';

		if(typeof mode !== 'undefined' && typeof filter !== 'undefined') {
			route = 'v1/service/order/list/'+mode+'/'+filter;
		}

		return $http.get(apiUrl+route, $(route));
	}

	// -------------------------------------------------

	api.request2FA = function(userPin, packageId) {
		var route = 'v1/user/2fa';
		return $http.post(apiUrl + route, { pin: userPin, id: packageId }, $(route));
	};
	
	api.packageOrderWith2FA = function(packageId, userPin, twoFactorCode, challengeResponse) {
		var route = 'v1/service/order2fa';

		var params = {
			pin: userPin,
			code: twoFactorCode,
			id: packageId,
		};

		if(typeof challengeResponse !== 'undefined') {
			params.challenge = challengeResponse;
		}

		return $http.post(apiUrl + route, params, $(route));
	};

	// -------------------------------------------------

	api.promoGet = function() {
		var route = 'v1/promo';
		return $http.get(apiUrl+route, $(route));
	}
	// -------------------------------------------------

	api.deviceReboot = function() {
		var route = 'v1/device/reboot';
		return $http.post(apiUrl+route, {}, $(route));
	}

	api.deviceRename = function(id, name) {
		var route = 'v1/device/name';
		return $http.post(apiUrl+route, { id: id, name: name }, $(route));
	}

	api.deviceResetKey = function(id) {
		var route = 'v1/auth/pair';
		return $http.patch(apiUrl+route, { id: id }, $(route));
	}

	// -------------------------------------------------

	api.simStatusGet = function() {
		var route = 'v1/sim/status';
		return $http.get(apiUrl+route, $(route));
	}

	api.simStatusSave = function(newServiceStatus) {
		var route = 'v1/sim/status';
		return $http.post(apiUrl+route, newServiceStatus, $(route));
	}

	api.simHistoryGet = function(from, to) {
		var route = 'v1/sim/history/from/'+from+'/to/'+to;
		return $http.get(apiUrl+route, $(route));
	}

	api.simTariffGet = function() {
		var route = 'v1/sim/package';
		return $http.get(apiUrl+route, $(route));
	}

	// -------------------------------------------------

	api.ottTokenGet = function() {
		var route = 'v1/ott/token';
		return $http.get(apiUrl+route, $(route));
	}

	api.ottQualityGet = function() {
		var route = 'v1/ott/quality';
		return $http.get(apiUrl+route, $(route));
	}

	api.ottQualityAvailableGet = function() {
		var route = 'v1/ott/quality/enabled';
		return $http.get(apiUrl+route, $(route));
	}

	api.ottIsHome = function() {
		var route = 'v1/ott/home';
		return $http.get(apiUrl+route, $(route));
	}

	api.ottDash = function(channelId) {
		var route = 'v1/ott/dash/'+channelId;
		return $http.get(apiUrl+route, $(route));
	}

	// -------------------------------------------------

	api.searchSuggestions = function(phrase) {
		phrase = phrase.replace(/[\\\/]/gi, ' ');
		var route = 'v2/search/suggest/'+phrase;

		return $http.get(apiUrl+route, $(route));
	}

	api.searchTitle = function(phrase, offset) {
		phrase = phrase.replace(/[\\\/]/gi, ' ');
		var route = 'v2/search/title/'+phrase+'/offset/'+offset;

		return $http.get(apiUrl+route, $(route));
	}

	api.searchCategoriesGet = function() {
		var route = 'v1/search/category';
		return $http.get(apiUrl+route, $(route));
	}

	api.searchCategory = function(category, offset) {
		var route = 'v1/search/category/'+category+'/offset/'+offset;
		return $http.get(apiUrl+route, $(route));
	}

	api.searchAwarded = function(offset) {
		var route = 'v1/search/awards/offset/'+offset;
		return $http.get(apiUrl+route, $(route));
	}

	// -------------------------------------------------

	api.vodGet = function() {
		var route = 'v1/vod';
		return $http.get(apiUrl+route, $(route));
	}

	api.vodCategoryGet = function(categoryId, offset) {
		if(typeof offset === 'undefined') offset = 0;
		var route = 'v1/vod/category/'+categoryId+'/offset/'+offset;

		return $http.get(apiUrl+route, $(route));
	}

	api.vodCustomQueueList = function() {
		var route = 'v1/vod/favorite';
		return $http.get(apiUrl+route, $(route));
	}

	api.vodCustomQueueGet = function(offset, size) {
		if(typeof offset === 'undefined') offset = 0;
		var route = 'v1/vod/favorite/offset/'+offset+'/size/'+size;

		return $http.get(apiUrl+route, $(route));
	}

	api.vodCustomQueueDelete = function(assetId) {
		var route = 'v1/vod/favorite/'+assetId;
		return $http.delete(apiUrl+route, $(route));
	}

	api.vodCustomQueuePush = function(assetId) {
		var route = 'v1/vod/favorite/'+assetId;
		return $http.post(apiUrl+route, {}, $(route));
	}

	api.vodGetOne = function(filmId) {
		var route = 'v1/vod/' + filmId;
		return $http.get(apiUrl+route, $(route));
	}

	// -------------------------------------------------

	api.tVodGet = function() {
		var route = 'v1/tvod';
		return $http.get(apiUrl+route, $(route));
	}

	api.tVodLease = function(assetId) {
		var route = 'v1/tvod/lease/'+assetId;
		return $http.post(apiUrl+route, {}, $(route));
	}

	api.tVodGetLeases = function() {
		var route = 'v1/tvod/lease';
		return $http.get(apiUrl+route, $(route));
	}

	api.tVodGetTicketPackage = function(assetId) {
		var route = 'v1/tvod/ticket/'+assetId;
		return $http.get(apiUrl+route, $(route));
	}

	api.tVodGetTicketPrices = function() {
		var route = 'v1/tvod/ticket';
		return $http.get(apiUrl+route, $(route));
	}

	// -------------------------------------------------

	return api;
}]);
