angular.module('webpanel').controller('OrderPinController', ['$scope', 'api', 'OrderService', function($scope, api, OrderService) {
    $scope.termsAccepted = { bsReference: false }
    $scope.jpin = { bsReference: null }

    $scope.defaultSuccessMsg = 'Dziękujemy za dokonanie zakupu. Pakiet będzie dostępny w ciągu kilku minut. ' +
    'Jeśli po tym czasie nie pojawi się na liście aktywnych usług, proszę zrestartować dekoder.';

    $scope.OrderService = OrderService;

    $scope.cancel = function() {
        $scope.analytics.trackEvent('order', 'cancel', OrderService.selectedPackage.name);

        $scope.termsAccepted.bsReference = false;
        $scope.jpin.bsReference = null;

        OrderService.reset();
    }

    $scope.order = function() {
        OrderService.setOrderInProgress(true);

        // save the jPIN to storage for potential SMS verification
        OrderService.setJpin($scope.jpin.bsReference);
        
        // call the 2FA API
        api.request2FA($scope.jpin.bsReference, OrderService.selectedPackage.id).then(function(response) {
            // proceed to sms verification
            OrderService.setVerificationStep(2);
        }).catch(function(response) {
            var pop = $scope.popup.create();
            
            var errorText = 'Przepraszamy, zakup nie powiódł się. Prawdopodobnie wprowadzono niepoprawny kod jPIN.';
            if (response.data && response.data.code === 402 && response.data.message === 'customer is suspended') {
                errorText = 'Nie można zrealizować zamówienia z powodu blokady windycacyjnej';
            }

            pop.setText(errorText);
            pop.addButton('Spróbuj ponownie', angular.noop, 'yes', true);

            $scope.analytics.trackEvent('order', 'fail', OrderService.selectedPackage.name);

            $scope.jpin.bsReference = null;
            $scope.termsAccepted.bsReference = false;
            
            OrderService.reset();

            $scope.popup.push(pop);
        });
    }
}]);