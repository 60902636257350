angular.module('webpanel').service('user', ['$rootScope', 'api', 'popup', 'auth', function($rootScope, api, popup, auth) {
	var userData = {};
	var observers = {};

	var getData = function() {
		return api.userGet().then(function(response) {
			userData = response.data;
			notifyObservers('change');
		}).catch(function() {
			notifyObservers('change');
			if(auth.isDemo()) return;

			var pop = popup.create();
			pop.setText('Wystąpił błąd podczas pobierania danych. Spróbuj ponownie. '+
				'Jeśli problem będzie się powtarzał, skontaktuj się ze swoim Operatorem.');
			pop.addButton('OK', angular.noop, 'yes', true);
			popup.push(pop);
		});
	}

	var has = function() {
		return !!userData.id;
	}

	var get = function() {
		return userData;
	}

	var addObserver = function(name, observer) {
		if(typeof observer !== 'function') return;
		if(typeof observers[name] !== 'undefined') return;
		observers[name] = observer;
	}

	var isVerified = function() {
		return userData.verified;
	}

	var hasPhoneNumber = function() {
		// if no phone number is set, then backend returns this weird thing - in order to not change half the code to support it, just deal with it as is (i've commited the same atrocity in tvuser.htm)
		return !(userData.cust_phone_number == "***-***-");
	}

	var removeObserver = function(name) {
		delete observers[name];
	}

	var notifyObservers = function(eventType, eventData) {
		for(var i in observers) observers[i](eventType, eventData);
	}

	$rootScope.$on('deviceAcquired', function() {
		getData();
	});

	$rootScope.$on('deviceLost', function() {
		userData = {};
		notifyObservers('change');
	});

	return {
		get: get,
		has: has,
		update: getData,
		addObserver: addObserver,
		removeObserver: removeObserver,
		isVerified: isVerified,
		isDemo: auth.isDemo,
		hasPhoneNumber: hasPhoneNumber
	}

}]);