angular.module('webpanel').controller('NavController', ['$scope', '$rootScope', '$route', 'auth', '$timeout', 'socket', 'device', '$window',
	function ($scope, $rootScope, $route, auth, $timeout, socket, device, $window) {

		$scope.category = '';
		$scope.position = '';

		$scope.userData = {};
		$scope.userMenuOpen = false;
		$scope.tilesMinHeihgt = '0';

		$scope.menuVisibleOnMobile = false;

		$scope.appVersion = 'v25.01';

		var menuMargin = [
			{ name: 'telewizja', offsetTile: 0, offsetList: 0, count: 7 },
			{ name: 'uslugi-tv', offsetTile: 0, offsetList: 0, count: 2 },
			{ name: 'uslugi-mobilne', offsetTile: 0, offsetList: 0, count: 6 },
			{ name: 'urzadzenia', offsetTile: 0, offsetList: 0, count: 2 },
			{ name: 'twoje-konto', offsetTile: 0, offsetList: 0, count: 4 },
		];

		var showDevaultNav = function () {
			var deviceTypes = device.getTypes();

			if (typeof deviceTypes.stb !== 'undefined') {
				$scope.show('telewizja');
			} else if (typeof deviceTypes.simcard !== 'undefined') {
				$scope.show('uslugi-mobilne');
			}
		}

		var setupNav = function (targetRoute) {
			var route;

			if (typeof targetRoute !== 'undefined') {
				route = targetRoute.$$route.originalPath;
			} else {
				route = $route.current.$$route.originalPath;
			}

			if (route === '/') {
				$scope.position = null;
				showDevaultNav();
			} else {
				var routeParts = route.split('/');
				var deviceTypes = device.getTypes();

				if (
					(routeParts[1] === 'uslugi-mobilne' && !deviceTypes.simcard) ||
					(routeParts[1] === 'telewizja' && !deviceTypes.stb) ||
					(routeParts[1] === 'uslugi-tv' && !deviceTypes.stb) ||
					(routeParts[1] === 'urzadzenia' && !deviceTypes.stb) ||
					((['nagrania', 'moja-lista-100', 'ochrona-rodzicielska', 'wyszukaj', 'wideo-na-zyczenie-vod']).includes(routeParts[2]) && $rootScope.access.card)
				) {
					console.log('no device compatible with nav element "' + routeParts[1] + '"');
					return;
				}

				$timeout(function () {
					$scope.show(routeParts[1]);
					$scope.position = routeParts[2];
				}, 0);
			}
		}

		$scope.toggleResponsiveMenu = function () {
			$scope.menuVisibleOnMobile = !$scope.menuVisibleOnMobile;
			// mogę pisać turbodyrektywę i bawić się w przekazywanie stanu menuVisibleOnMobile,
			// albo mogę po prostu zrobić tak. Jakby coś, to nikt nic nie widział (patrz, leci superman!)
			document.querySelector('.nav__container').scrollTop = 0;
		}

		$scope.closeResponsiveMenu = function () {
			$scope.menuVisibleOnMobile = false;
		}

		$scope.getMarginTile = function (what) {
			for (var i in menuMargin) {
				if (menuMargin[i].name === what) {
					return menuMargin[i].offsetTile;
				}
			}
		}

		$scope.getMarginList = function (what) {
			for (var i in menuMargin) {
				if (menuMargin[i].name === what) {
					return menuMargin[i].offsetList;
				}
			}
		}

		$scope.show = function (what, permission) {
			if (typeof permission !== 'undefined') {
				if (typeof permission === 'string') permission = [permission];

				for (var i in permission) {
					if (!$rootScope.access[permission[i]]) {
						$scope.showLoginPrompt() // mainController
						return;
					}
				}
			}

			// przesuwa ikonę _kolejnego_ menu (offsetTile) i ustawia listę w pionie (offsetList) żeby była iluzja menu rozwijanego
			for (var i = 0; i < menuMargin.length; i++) {
				if (menuMargin[i].name === what) {
					if (typeof menuMargin[i + 1] !== 'undefined') menuMargin[i + 1].offsetTile = (3.75 * menuMargin[i].count);
					menuMargin[i].offsetList = 5 * (i + 1);
				} else {
					if (typeof menuMargin[i + 1] !== 'undefined') menuMargin[i + 1].offsetTile = 0;
					menuMargin[i].offsetList = 0;
				}
			}

			$scope.category = what;
		}

		$scope.select = function (what) {
			$scope.position = what;
		}

		$scope.toggleUserMenu = function () {
			$scope.userMenuOpen = !$scope.userMenuOpen;
		}

		$scope.closeUserMenu = function () {
			$scope.userMenuOpen = false;
		}

		$scope.logout = function () {
			socket.stop();
			auth.destroy();
			$scope.location.path('/');

			// Firefox sobie nie radzi z tym przekierowaniem po wszystkich udziwnieniach
			// wprowadzonych na potrzeby linków dla niezlaogowanych... (uwaga, WTF)
			var baseHref = document.getElementsByTagName('base')[0].getAttribute('href');
			if (!baseHref) baseHref = '/';

			$window.location.href = baseHref;
		}

		$scope.navHome = function () {
			$scope.location.path('/');
		}

		// ------------------------------------------------

		device.addObserver('navDeviceObserver', function (type) {
			switch (type) {
				case 'change':
					setupNav();
					break;
			}
		});

		$scope.user.addObserver('navUserObserver', function () {
			$scope.userData = $scope.user.get();
		});

		// ------------------------------------------------

		$scope.$on('$routeChangeSuccess', function (_, current) {
			setupNav(current);
			$scope.menuVisibleOnMobile = false;
		});

		$scope.$on('$destroy', function () {
			device.removeObserver('navDeviceObserver');
			$scope.user.removeObserver('navUserObserver');
		});

		$timeout(function () {
			$scope.show($scope.category);
		}, 0);

	}]);