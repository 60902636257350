angular.module('webpanel').controller('CaptchaController', ['$scope', 'api', 'OrderService', function($scope, api, OrderService) {

    $scope.OrderService = OrderService;

    $scope.cancel = function() {
        $scope.analytics.trackEvent('order', 'cancel', OrderService.selectedPackage.name);

        OrderService.reset();
    }

    $scope.waitChallengeCompleted = function(response) {
        OrderService.setOrderInProgress(true);
        OrderService.setCaptchaResponse(response);
        OrderService.setVerificationStep(2);
        OrderService.setOrderInProgress(false);
    };

    $scope.cancelChallenge = function() {
        //OrderService.setCaptchaResponse("xyz"); //enable on dev in order to work without captcha
        OrderService.setVerificationStep(2);
        OrderService.setOrderInProgress(false);
    }
}]);
