angular.module('webpanel').controller('OrderSMSController', ['$scope', 'api', 'OrderService', function($scope, api, OrderService) {
    $scope.smsCode = { value: null };
    $scope.smsCodeResendCount = 0;
    $scope.maxResendAttempts = 3;

    $scope.OrderService = OrderService;

    $scope.cancel = function() {
        $scope.analytics.trackEvent('order', 'cancel', OrderService.selectedPackage.name);

        OrderService.reset();
    }

    $scope.resendCode = function() {
        if ($scope.smsCodeResendCount >= $scope.maxResendAttempts) {
            var pop = $scope.popup.create();
            pop.setText('Przekroczono maksymalną liczbę prób wysłania kodu.');
            pop.addButton('OK', angular.noop, 'yes', true);
            $scope.popup.push(pop);
            return;
        }

        OrderService.setOrderInProgress(true);
        var savedPin = OrderService.jpin;

        api.request2FA(savedPin, OrderService.selectedPackage.id).then(function() {
            $scope.smsCodeResendCount++;
            OrderService.orderInProgress = false;

            var pop = $scope.popup.create();
            pop.setText('Kod został wysłany ponownie.');
            pop.addButton('OK', angular.noop, 'yes', true);
            $scope.popup.push(pop);
        }).catch(function() {
            OrderService.setOrderInProgress(false);

            var pop = $scope.popup.create();
            pop.setText('Nie udało się wysłać kodu.');
            pop.addButton('OK', angular.noop, 'yes', true);
            $scope.popup.push(pop);
        });
    };

    $scope.ordersms = function() {
        OrderService.setOrderInProgress(true);
        var savedPin = OrderService.jpin;

        api.packageOrderWith2FA(
            parseInt(OrderService.selectedPackage.id),
            savedPin,
            $scope.smsCode.value,
            OrderService.captchaResponse
        ).then(function() {
            var pop = $scope.popup.create();
            pop.setText('Dziękujemy za dokonanie zakupu.');
            pop.addButton('OK', angular.noop, 'yes', true);
            $scope.popup.push(pop);

            OrderService.reset();
        }).catch(function(response) {
            if (response.status === 429) {
                // Transition to captcha step
                OrderService.setVerificationStep(3);
                OrderService.setOrderInProgress(false);

                return;
            }

            var pop = $scope.popup.create();
            pop.setText('Nieprawidłowy kod SMS. Spróbuj ponownie.');
            pop.addButton('Spróbuj ponownie', angular.noop, 'yes', true);
            $scope.popup.push(pop);

            $scope.smsCode.value = null;
            OrderService.setOrderInProgress(false);
        });
    };
}]);
